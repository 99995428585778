import { Badge, InlineStack, Layout } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from '../../ToastProvider';
import { useReviewAccount, useReviewAccountJobs } from '../../hooks/reviews/useReviewAccount';
import AccountView from '../AccountView';
import AdminPage from '../AdminPage';
import AppCreditModal from '../AppCreditModal';
import ImportJobModelTable from '../ImportJobModelTable';
import JobsView from '../JobsView';
import { ReviewDetailsSummary } from '../ReviewDetailsSummary';
export default function ReviewAccountView() {
	const { showToast } = useToast();

	const { accountId } = useParams();
	const { account } = useReviewAccount(accountId);
	const { jobs, onLoadJobs, isJobsLoading } = useReviewAccountJobs(accountId);

	const [showAppCreditModal, setShowAppCreditModal] = useState(false);
	const onCloseAppCreditModal = useCallback(() => setShowAppCreditModal(false), []);

	const billingActions = [];

	const badges = [];
	if (account) {
		if (account.trialEndsAt) {
			badges.push(
				<Badge key="trial" tone="info">
					trial ends:{' '}
					{new Date(account.trialEndsAt).toLocaleDateString('en-US', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit'
					})}
				</Badge>
			);
		}
		if (account.suspended) {
			badges.push(
				<Badge key="suspended" tone="warning">
					suspended
				</Badge>
			);
		}
	}
	return (
		<AdminPage
			title={
				<InlineStack align="center">
					<div>{account ? account.name : 'Popup account'}</div>
					{badges}
				</InlineStack>
			}
			pageTitle={account?.name ?? 'Popup account'}
			backAction={{ content: 'Dashboard', url: '/' }}
			secondaryActions={billingActions}>
			<Layout sectioned>
				<AccountView account={account} app="REVIEWS" />
				<ReviewDetailsSummary id={account?.id} />
				<ImportJobModelTable id={account?.id} onCopy={() => showToast('Public id copied!')} />
				<JobsView onLoad={onLoadJobs} jobs={jobs} isLoading={isJobsLoading} />
			</Layout>
			{account && <AppCreditModal account={account} app="REVIEWS" onClose={onCloseAppCreditModal} open={showAppCreditModal} />}
		</AdminPage>
	);
}
