import {
	Card,
	DateTimePicker,
	FormatNumber,
	FormatPercent,
	getLanguages,
	getNumber,
	LanguageSelector,
	Stack,
	TextStyle
} from '@segunosoftware/equinox';
import {
	Badge,
	Banner,
	Button,
	Checkbox,
	ChoiceList,
	Form,
	FormLayout,
	InlineStack,
	Layout,
	Link,
	Modal,
	SkeletonBodyText,
	Spinner,
	TextField
} from '@shopify/polaris';
import { addDays, formatISO, fromUnixTime, getUnixTime, subDays } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import MarketingPlatformConnections from '../../hooks/marketingplatform/MarketingPlatformConnections';
import { useIpPools } from '../../hooks/marketingplatform/useIpPools';
import {
	useMarketingPlatformAccount,
	useMarketingPlatformAccountSuppressionTags,
	useMarketingPlatformManualJobs
} from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import { useMarketingPlatformAccountActions } from '../../hooks/marketingplatform/useMarketingPlatformAccountActions';
import { useMarketingPlatformAccountDetails } from '../../hooks/marketingplatform/useMarketingPlatformAccountDetails';
import { useMarketingPlatformAccountSendingDomains } from '../../hooks/marketingplatform/useMarketingPlatformAccountSendingDomains';
import { useMarketingPlatformThemeSupport } from '../../hooks/marketingplatform/useMarketingPlatformThemeSupport';
import { useUser } from '../../hooks/useUser';
import AccountView, { isAdmin, isLeadOrAdmin, isSupport } from '../AccountView';
import AdminPage from '../AdminPage';
import AppCreditModal from '../AppCreditModal';
import ConversionFormatMoney from '../ConversionFormatMoney';
import { DateWithDistance } from '../DateTime';
import JobsView from '../JobsView';
import Sus from '../Sus';
import Automations from './Automations';
import DraftNewsletters from './DraftNewsletters';
import ManualJobs from './ManualJobs';
import MarketingPlatformAccountAppPurchases from './MarketingPlatformAccountAppPurchases';
import MarketingPlatformBillingDetails from './MarketingPlatformBillingDetails';
import RecentNewsletters from './RecentNewsletters';
import ResourceFeedbackCard from './ResourceFeedbackCard';
import ScheduledNewsletters from './ScheduledNewsletters';
import SuppressionTagsField from './SuppressionTagsField';

const EXPORT_TYPES = [
	{ label: 'Clean bounce check (no spam protect emails)', value: 'subscriber-emails-sans-spam-protect' },
	{ label: 'Bounce check (subscriber emails)', value: 'subscriber-emails' },
	{ label: 'Sniff test (subscriber profiles)', value: 'subscriber-profiles' },
	{ label: 'Engaged', value: 'engaged' },
	{ label: 'Unengaged (1 year)', value: 'unengaged-emails-one-year' },
	{ label: 'Unengaged (2 years)', value: 'unengaged-emails-two-years' },
	{ label: 'Non-openers (all time)', value: 'nonopeners-all' },
	{ label: 'Unsubscribed (including bounces and complaints)', value: 'unsubscribed' },
	{ label: 'Spam Protect unsubscribes', value: 'failed-validation' }
];

const CLEAN_LEVELS = {
	ALL: 'All',
	ALL_EXCEPT_ROLE: 'Role addresses allowed',
	NONE: 'None'
};

function getTestEmailEventsLink(email) {
	const startTime = formatISO(new Date());
	const endTime = formatISO(subDays(new Date(), 1));
	return `https://app.sparkpost.com/reports/message-events?from=${encodeURIComponent(startTime)}&range=day&recipients=${encodeURIComponent(
		email
	)}&to=${encodeURIComponent(endTime)}`;
}

export default function MarketingPlatformAccountView() {
	const { accountId } = useParams();
	const user = useUser();
	const { account, jobs, onLoadJobs, isJobsLoading, appPurchases, isAppPurchasesLoading } = useMarketingPlatformAccount(accountId);
	const { details, onLoadDetails, customerSummary } = useMarketingPlatformAccountDetails(accountId);
	const { sendingDomains = [], isLoading: isSendingDomainsLoading } = useMarketingPlatformAccountSendingDomains(accountId);

	const { themeSupport, isLoading: isLoadingThemeSupport } = useMarketingPlatformThemeSupport(accountId);

	const { manualJobs, isLoading: isLoadingManualJobs, refetch: refetchManualJobs } = useMarketingPlatformManualJobs(accountId);
	const { suppressionTags: allSuppressionTags } = useMarketingPlatformAccountSuppressionTags(accountId);

	const { ipPools = [], isIpPoolsLoading } = useIpPools();
	const {
		setSuspended,
		isSettingSuspended,
		setSuspicious,
		isSettingSuspicious,
		setTrusted,
		isSettingTrusted,
		setTrustedImporter,
		isSettingTrustedImporter,
		verifyDomain,
		isVerifyingDomain,
		resetWarming,
		isResettingWarming,
		setSendingDomain: onSetSendingDomain,
		isSettingSendingDomain,
		setComplianceHold,
		isSettingComplianceHold,
		setAllowSendingOverride,
		isSettingAllowSendingOverride,
		setAllowSendingWhileWarming,
		isSettingAllowSendingWhileWarming,
		setIpPool: onSetIpPool,
		isSettingIpPool,
		setUnsubscribeText: onSetUnsubscribeText,
		isSettingUnsubscribeText,
		setContact,
		isSettingContact,
		setCsmNote,
		isSettingCsmNote,
		setScreenRecord: onSetScreenRecord,
		isSettingScreenRecord,
		exportCustomers,
		isExportingCustomers,
		setListCleaningLevel: onSetListCleaningLevel,
		isSettingListCleaningLevel,
		updateTrial,
		isUpdatingTrial,
		exportAnalysis,
		isExportingAnalysis,
		analysisExportError,
		setResourceFeedback,
		isSettingResourceFeedback,
		setSuppressionTags: onSetSuppressionTags,
		isSettingSuppressionTags
	} = useMarketingPlatformAccountActions(accountId);

	const [showSendingDomainModal, setShowSendingDomainModal] = useState(false);
	const [showConfirmSuspendedModal, setShowConfirmSuspendedModal] = useState(false);
	const [showConfirmSuspiciousModal, setShowConfirmSuspiciousModal] = useState(false);
	const [showConfirmDomainVerifyModal, setShowConfirmDomainVerifyModal] = useState(false);
	const [showConfirmResetWarmingModal, setShowConfirmResetWarmingModal] = useState(false);
	const [showConfirmComplianceHoldModal, setShowConfirmComplianceHoldModal] = useState(false);
	const [showConfirmTrustedModal, setShowConfirmTrustedModal] = useState(false);
	const [showConfirmTrustedImporterModal, setShowConfirmTrustedImporterModal] = useState(false);
	const [showConfirmAllowSendingOverrideModal, setShowConfirmAllowSendingOverrideModal] = useState(false);
	const [showConfirmAllowSendingWhileWarmingModal, setShowConfirmAllowSendingWhileWarmingModal] = useState(false);
	const [showScreenRecordModal, setShowScreenRecordModal] = useState(false);
	const [showSetUnsubscribeModal, setShowSetUnsubscribeModal] = useState(false);
	const [showSetContactModal, setShowSetContactModal] = useState(false);
	const [showSetIpPoolModal, setShowSetIpPoolModal] = useState(false);
	const [showExportCustomersModal, setShowExportCustomersModal] = useState(false);
	const [showListCleaningLevelModal, setShowListCleaningLevelModal] = useState(false);
	const [showUpdateTrialModal, setShowUpdateTrialModal] = useState(false);
	const [listCleaningLevel, setListCleaningLevel] = useState('ALL');
	const [showCsmNoteModal, setShowCsmNoteModal] = useState(false);
	const [showResourceFeedbackModal, setShowResourceFeedbackModal] = useState(false);
	const [includeCustomerTagsForExport, setIncludeCustomerTagsForExport] = useState(false);
	const [showAppCreditModal, setShowAppCreditModal] = useState(false);
	const [showSuppressionTagsModal, setShowSuppressionTagsModal] = useState(false);
	const [suppressionTags, setSuppressionTags] = useState([]);

	const [screenRecord, setScreenRecord] = useState(false);
	const [complianceHoldStatus, setComplianceHoldStatus] = useState('NONE');
	const [sendingDomain, setSendingDomain] = useState('');
	const [ipPool, setIpPool] = useState(0);
	const [unsubscribeText, setUnsubscribeText] = useState('');
	const [unsubscribeLanguage, setUnsubscribeLanguage] = useState('');
	const [contactName, setContactName] = useState('');
	const [contactEmail, setContactEmail] = useState('');
	const [exportType, setExportType] = useState('subscriber-emails-sans-spam-protect');
	const [updateTrialOption, setUpdateTrialOption] = useState(['extend-trial']);
	const isExtendTrialOption = updateTrialOption[0] === 'extend-trial';
	const [trialExtensionDate, setTrialExtensionDate] = useState(null);
	const [csmNoteFields, setCsmNoteFields] = useState({
		title: '',
		note: '',
		status: 'info',
		dismissedAt: null
	});
	const [resourceFeedbackMessage, setResourceFeedbackMessage] = useState('');
	const isSniffTestExport = exportType === 'subscriber-profiles';

	useEffect(() => {
		if (!isSettingSendingDomain) {
			setShowSendingDomainModal(false);
		}
		if (!isSettingUnsubscribeText) {
			setShowSetUnsubscribeModal(false);
		}
		if (!isSettingContact) {
			setShowSetContactModal(false);
		}
		if (!isSettingSuspended) {
			setShowConfirmSuspendedModal(false);
		}
		if (!isSettingSuspicious) {
			setShowConfirmSuspiciousModal(false);
		}
		if (!isVerifyingDomain) {
			setShowConfirmDomainVerifyModal(false);
		}
		if (!isResettingWarming) {
			setShowConfirmResetWarmingModal(false);
		}
		if (!isSettingComplianceHold) {
			setShowConfirmComplianceHoldModal(false);
		}
		if (!isSettingTrusted) {
			setShowConfirmTrustedModal(false);
		}
		if (!isSettingTrustedImporter) {
			setShowConfirmTrustedImporterModal(false);
		}
		if (!isSettingAllowSendingOverride) {
			setShowConfirmAllowSendingOverrideModal(false);
		}
		if (!isSettingAllowSendingWhileWarming) {
			setShowConfirmAllowSendingWhileWarmingModal(false);
		}
		if (!isSettingIpPool) {
			setShowSetIpPoolModal(false);
		}
		if (!isSettingScreenRecord) {
			setShowScreenRecordModal(false);
		}
		if (!isSettingListCleaningLevel) {
			setShowListCleaningLevelModal(false);
		}
		if (!isUpdatingTrial) {
			setShowUpdateTrialModal(false);
		}
		if (!isSettingCsmNote) {
			setShowCsmNoteModal(false);
		}
		if (!isSettingResourceFeedback) {
			setShowResourceFeedbackModal(false);
		}
		if (!isSettingSuppressionTags) {
			setShowSuppressionTagsModal(false);
		}
	}, [
		onLoadDetails,
		isResettingWarming,
		isSettingAllowSendingOverride,
		isSettingAllowSendingWhileWarming,
		isSettingComplianceHold,
		isSettingIpPool,
		isSettingScreenRecord,
		isSettingSendingDomain,
		isSettingSuspended,
		isSettingSuspicious,
		isSettingTrusted,
		isSettingUnsubscribeText,
		isVerifyingDomain,
		isSettingContact,
		isSettingListCleaningLevel,
		isUpdatingTrial,
		isSettingCsmNote,
		isSettingResourceFeedback,
		isSettingTrustedImporter,
		isSettingSuppressionTags
	]);

	useEffect(() => {
		if (showSuppressionTagsModal) {
			setSuppressionTags(account?.suppressionTags ?? []);
		}
	}, [showSuppressionTagsModal, account?.suppressionTags]);

	function onShowSuppressionTagsModal() {
		setShowSuppressionTagsModal(true);
	}

	function performSetSuppressionGroups() {
		onSetSuppressionTags(suppressionTags);
	}

	function onSetCsmNoteField(value, id) {
		setCsmNoteFields({
			...csmNoteFields,
			[id]: value
		});
	}

	function onShowCsmNoteModal() {
		setShowCsmNoteModal(true);
		const { title = '', note = '', status = 'info', dismissedAt = null } = account.csmNote || {};
		setCsmNoteFields({
			title,
			note,
			status,
			dismissedAt
		});
	}

	function onSetCsmNote() {
		setCsmNote(csmNoteFields);
	}

	function onRemoveCsmNote() {
		setCsmNote(null);
	}

	function onShowSendingDomainModal() {
		setShowSendingDomainModal(true);
		setSendingDomain(account.sendingDomain);
	}

	function performSetSendingDomain() {
		onSetSendingDomain(sendingDomain);
	}

	function onShowConfirmSuspiciousModal() {
		setShowConfirmSuspiciousModal(true);
	}

	function onShowConfirmDomainVerifyModal() {
		setShowConfirmDomainVerifyModal(true);
	}

	function onShowConfirmWarmingResetModal() {
		setShowConfirmResetWarmingModal(true);
	}

	function onShowConfirmComplianceHoldModal() {
		setShowConfirmComplianceHoldModal(true);
		setComplianceHoldStatus(account.complianceHoldStatus);
	}

	function onShowConfirmTrustedModal() {
		setShowConfirmTrustedModal(true);
	}

	function onShowConfirmTrustedImporterModal() {
		setShowConfirmTrustedImporterModal(true);
	}

	function onShowConfirmAllowSendingOverrideModal() {
		setShowConfirmAllowSendingOverrideModal(true);
	}

	function onShowConfirmAllowSendingWhileWarmingModal() {
		setShowConfirmAllowSendingWhileWarmingModal(true);
	}

	function onShowResourceFeedbackModal() {
		setResourceFeedbackMessage('');
		setShowResourceFeedbackModal(true);
	}

	function onSetResourceFeedback() {
		if (!Boolean(resourceFeedbackMessage)) {
			return;
		}
		setResourceFeedback(resourceFeedbackMessage);
	}

	function onShowIpPoolModal() {
		setIpPool(account.ipPool);
		setShowSetIpPoolModal(true);
	}

	function performSetIpPool() {
		onSetIpPool(ipPool || 0);
	}

	function onShowScreenRecordModal() {
		setScreenRecord(account.shouldRecordScreen);
		setShowScreenRecordModal(true);
	}

	function performSetScreenRecord() {
		onSetScreenRecord(screenRecord);
	}

	function onShowSetUnsubscribeModal() {
		const { language } = getLanguageOptions()[0];
		setUnsubscribeLanguage(language);
		setUnsubscribeText(account.languageToUnsubscribeText[language]);
		setShowSetUnsubscribeModal(true);
	}

	function performSetUnsubscribeText() {
		onSetUnsubscribeText({ language: unsubscribeLanguage, unsubscribeText });
	}

	function onChangeUnsubscribeLanguage(language) {
		setUnsubscribeLanguage(language);
		setUnsubscribeText(account.languageToUnsubscribeText[language]);
	}

	function getLanguageOptions() {
		return getLanguages().filter(({ language }) => Object.keys(account.languageToUnsubscribeText).includes(language));
	}

	function onShowSetContactModal() {
		setContactName(account.contactName);
		setContactEmail(account.contactEmail);
		setShowSetContactModal(true);
	}

	function performSetContact() {
		setContact({ contactName, contactEmail });
	}

	function onShowExportCustomersModal() {
		setShowExportCustomersModal(true);
		setIncludeCustomerTagsForExport(false);
	}

	function onHideExportCustomersModal() {
		setShowExportCustomersModal(false);
	}

	function performExportCustomers() {
		exportCustomers({ exportType, includeTags: isSniffTestExport && includeCustomerTagsForExport });
	}

	function onShowListCleaningLevelModal() {
		setListCleaningLevel(account.listCleaningLevel);
		setShowListCleaningLevelModal(true);
	}

	function performSetListCleaningLevel() {
		onSetListCleaningLevel(listCleaningLevel);
	}

	function onShowUpdateTrialModal() {
		const trialDate = account.trialEndsAt ? getUnixTime(account.trialEndsAt) : getUnixTime(addDays(new Date(), account.trialDays));
		setTrialExtensionDate(trialDate);
		setShowUpdateTrialModal(true);
	}

	function onUpdateTrial() {
		updateTrial(isExtendTrialOption ? trialExtensionDate : 0);
	}

	function getUpdateTrialOptions() {
		let options = [{ label: 'Extend trial', value: 'extend-trial' }];
		if (account.trialEndsAt) {
			options.push({ label: 'Remove trial', value: 'remove-trial' });
		}
		return options;
	}

	function getUpdateTrialBannerText() {
		let text = [];
		if (details?.activeChargeAmount > 0) {
			text.push(
				<p>
					Extending or removing the trial will cancel the active charge.
					<br />
				</p>
			);
		}
		if (!isExtendTrialOption && !isOnComplianceHoldOrFree) {
			text.push(<p>Removing the trial will cause a new trial to automatically begin.</p>);
		}
		return text;
	}

	const isOnComplianceHoldOrFree = account?.complianceHold || account?.billedSubscribers <= account?.freeTierCap;

	const billingActions = [];

	function addBillingOptions(hasPermissions, options) {
		if (hasPermissions) {
			billingActions.push(...options);
		}
	}

	if (account) {
		const isSupportRole = isSupport(user);
		const isLeadOrAdminRole = isLeadOrAdmin(user);

		addBillingOptions(isSupportRole, [
			{
				content: 'Export analysis 🍤',
				onAction: exportAnalysis,
				disabled: isExportingAnalysis
			},
			{
				content: 'Export customers',
				onAction: onShowExportCustomersModal,
				disabled: isExportingCustomers
			},
			{
				content: 'Set customer success note',
				onAction: onShowCsmNoteModal,
				disabled: isSettingCsmNote
			},
			{
				content: 'Update trial',
				onAction: onShowUpdateTrialModal,
				disabled: isUpdatingTrial
			}
		]);

		addBillingOptions(isLeadOrAdminRole, [
			{
				content: account.complianceHold ? 'Modify compliance status' : 'Add compliance hold',
				onAction: onShowConfirmComplianceHoldModal,
				disabled: isSettingComplianceHold
			},
			{ content: 'Set IP pool', onAction: onShowIpPoolModal, disabled: isSettingIpPool },
			{
				content: account.trustedImporter ? 'Untrust account imports' : 'Trust account imports',
				onAction: onShowConfirmTrustedImporterModal,
				disabled: isSettingTrustedImporter
			},
			{
				content: account.trusted ? 'Untrust account' : 'Trust account',
				onAction: onShowConfirmTrustedModal,
				disabled: isSettingTrusted
			},
			{
				content: account.suspicious ? 'Unmark suspicious' : 'Mark suspicious',
				onAction: onShowConfirmSuspiciousModal,
				disabled: isSettingSuspicious
			}
		]);

		addBillingOptions(isLeadOrAdminRole, [
			{
				content: 'Set Shopify notice',
				onAction: onShowResourceFeedbackModal,
				disabled: isSettingResourceFeedback
			}
		]);

		addBillingOptions(true, [
			{ content: 'Set unsubscribe text', onAction: onShowSetUnsubscribeModal, disabled: isSettingUnsubscribeText },
			{ content: 'Set contact', onAction: onShowSetContactModal, disabled: isSettingContact }
		]);

		addBillingOptions(isLeadOrAdminRole, [
			{
				content: 'Set screen record',
				onAction: onShowScreenRecordModal,
				disabled: isSettingScreenRecord
			},
			{
				content: 'Issue app credit',
				onAction: () => setShowAppCreditModal(true)
			}
		]);

		addBillingOptions(account.sendingDisabledFromPlan, [
			{
				content: account.allowSendingOverride ? 'Disallow sending' : 'Allow sending',
				onAction: onShowConfirmAllowSendingOverrideModal,
				disabled: isSettingAllowSendingOverride
			}
		]);

		addBillingOptions(isAdmin(user), [
			{
				content: account.suspended ? 'Unsuspend' : 'Suspend',
				onAction: () => setShowConfirmSuspendedModal(true),
				disabled: isSettingSuspended
			}
		]);
	}

	const badges = [];
	if (account) {
		if (account.trialEndsAt) {
			badges.push(
				<Badge key="trial" status="info">
					trial ends:{' '}
					{new Date(account.trialEndsAt).toLocaleDateString('en-US', {
						year: 'numeric',
						month: '2-digit',
						day: '2-digit'
					})}
				</Badge>
			);
		}
		if (account.suspended) {
			badges.push(
				<Badge key="suspended" tone="warning">
					suspended
				</Badge>
			);
		}
		if (account.suspicious) {
			badges.push(
				<Badge key="suspicious" tone="warning">
					<Stack alignment="center" spacing="extraTight">
						<span>kinda sus</span>
						<Sus />
					</Stack>
				</Badge>
			);
		}
		if (account.complianceHold) {
			badges.push(
				<Badge key="complianceHold" tone="attention">
					compliance: {account.complianceHoldStatus}
				</Badge>
			);
		}
		if (account.trusted) {
			badges.push(
				<Badge key="trusted" tone="success">
					trusted
				</Badge>
			);
		}
		if (account.risky) {
			badges.push(
				<Badge key="risky" tone="warning">
					risky
				</Badge>
			);
		}
		if (account.suppressionTags.length > 0) {
			badges.push(
				<Badge key="suppressionTags" tone="attention">
					suppressions
				</Badge>
			);
		}
		if (account.ipPool !== 0 && ipPools.length > 0 && isLeadOrAdmin(user)) {
			const poolName = (ipPools.find(ip => ip.id === account.ipPool) || { name: 'Loading...' }).name;
			badges.push(
				<Badge key="ippool" tone="default">
					IP pool: {poolName}
				</Badge>
			);
		}
	}
	const ipPoolsToShow = [{ label: 'default', value: 0 }].concat(
		ipPools.map(p => ({ label: p.name, value: p.id })).sort((a, b) => a.label.localeCompare(b.label))
	);
	const domainActions = [];
	const isSendingDomainShared = account?.sendingDomain?.includes('seguno-mail.com');
	const hasCustomDomain = account && account.domain !== account.shop;
	if (account && isSendingDomainShared && hasCustomDomain) {
		domainActions.push({ content: 'Verify', onAction: onShowConfirmDomainVerifyModal });
	}
	if (account && !isSendingDomainShared && account.sendingDomainWarmed && isSupport(user)) {
		domainActions.push({ content: 'Reset warming', onAction: onShowConfirmWarmingResetModal });
	}
	if (isLeadOrAdmin(user)) {
		domainActions.push({ content: 'Edit', onAction: onShowSendingDomainModal });
	}

	const onCloseAppCreditModal = useCallback(() => setShowAppCreditModal(false), []);

	return (
		<AdminPage
			title={
				<InlineStack align="center">
					<div>{account?.name ?? 'Marketing platform account'}</div>
					{badges}
				</InlineStack>
			}
			pageTitle={account?.name ?? 'Marketing platform account'}
			backAction={{ content: 'Dashboard', url: '/' }}
			subtitle={
				isExportingAnalysis ? (
					<Stack alignment="center">
						<TextStyle variation="subdued">Exporting analysis</TextStyle>
						<Spinner size="small" />
					</Stack>
				) : analysisExportError ? (
					<TextStyle variation="negative">Something went wrong</TextStyle>
				) : (
					''
				)
			}
			secondaryActions={billingActions}>
			<Layout sectioned>
				<AccountView account={account} app="SEGUNO" />
				{account && <ResourceFeedbackCard account={account} />}
				{account && account.csmNote && (
					<Card
						title="Customer success note"
						actions={[
							{ content: 'Edit', onAction: onShowCsmNoteModal },
							{ content: 'Remove', onAction: onRemoveCsmNote, loading: isSettingCsmNote }
						]}
						sectioned>
						<Stack vertical>
							<Banner title={account.csmNote.title || undefined} tone={account.csmNote.status}>
								<p>{account.csmNote.note}</p>
							</Banner>
							{Boolean(account.csmNote.dismissedAt) && (
								<p>
									Dismissed at <DateWithDistance date={account.csmNote.dismissedAt} />
								</p>
							)}
						</Stack>
					</Card>
				)}
				{account && (
					<Card title="Sending" actions={domainActions} sectioned>
						<div>
							<strong>Domain</strong>: {account.sendingDomain}
						</div>
						{!isSendingDomainShared && (
							<>
								<div>
									<strong>Domain warmed</strong>: {account.sendingDomainWarmed ? 'Yes' : 'No'}
								</div>
								<div>
									<strong>Sending during warming</strong>: {account.allowSendingWhileWarming ? 'Allowed' : 'Disallowed'}{' '}
									<Link onClick={onShowConfirmAllowSendingWhileWarmingModal}>Change</Link>
								</div>
							</>
						)}
						<div>
							<strong>From email</strong>: {account.fromEmail}
						</div>
						<div>
							<strong>Reply email</strong>: {account.replyEmail}
						</div>
						<Stack spacing="tight">
							<div>
								<strong>Test email(s)</strong>:{' '}
							</div>
							{account.testEmails.map(testEmail => (
								<Link key={testEmail} url={getTestEmailEventsLink(testEmail)} target="_blank">
									{testEmail}
								</Link>
							))}
						</Stack>
						<div>
							<strong>Subaccount</strong>:{' '}
							<Link url={`https://app.sparkpost.com/account/subaccounts/${account.espAccountId}`} target="_blank">
								{account.espAccountId}
							</Link>
						</div>
						{isAdmin(user) && (
							<div>
								<strong>Subaccount API Key</strong>: {account.espApiKey}
							</div>
						)}
						{isSendingDomainsLoading && (
							<div>
								<SkeletonBodyText lines={2} />
							</div>
						)}
						<Stack spacing="tight" vertical>
							{sendingDomains.map(sendingDomain => {
								function getBadgeForStatus(status, prefix = '') {
									const prefixText = prefix ? ` ${prefix}` : '';
									switch (status) {
										case 'valid':
											return <Badge tone="success">{prefixText}Valid</Badge>;
										case 'pending':
											return <Badge tone="new">{prefixText}Pending</Badge>;
										case 'blocked':
											return <Badge tone="critical">{prefixText}Blocked</Badge>;
										case 'invalid':
											return <Badge tone="critical">{prefixText}Invalid</Badge>;
										case 'unverified':
											return <Badge tone="warning">{prefixText}Unverified</Badge>;
										default:
											return null;
									}
								}
								const isBounceDomain =
									sendingDomain.isDefaultBounceDomain ||
									!Boolean(sendingDomain.dkim?.selector) ||
									sendingDomain.domain.startsWith('bounces.');
								return (
									<Stack key={sendingDomain.domain} spacing="none" vertical>
										<Stack>
											<div>
												<strong>
													<LinkedDnsDomain domain={sendingDomain.domain} />
												</strong>
											</div>
											{isBounceDomain && <Badge tone="new">Bounce</Badge>}
											{getBadgeForStatus(isBounceDomain ? sendingDomain.status.cnameStatus : sendingDomain.status.dkimStatus, 'Record: ')}
											{getBadgeForStatus(sendingDomain.status.complianceStatus, 'Compliance: ')}
											{!isBounceDomain && (
												<Link
													url={`https://dmarcian.com/dmarc-inspector/?domain=${encodeURIComponent(sendingDomain.domain)}`}
													target="_blank">
													DMARC
												</Link>
											)}
											<Link url={`https://app.sparkpost.com/domains/details/sending-bounce/${sendingDomain.domain}`} target="_blank">
												View in Sparkpost
											</Link>
										</Stack>
										{!isBounceDomain && sendingDomain.dkim?.selector && (
											<DkimContainer>
												<div>
													<strong>DKIM</strong>:{' '}
													<LinkedDnsDomain domain={`${sendingDomain.dkim.selector}._domainkey.${sendingDomain.domain}`} />
												</div>
												<div>
													<strong>Value</strong>: <Dkim>v=DKIM1; k=rsa; h=sha256; p={sendingDomain.dkim.publicKey}</Dkim>
												</div>
											</DkimContainer>
										)}
									</Stack>
								);
							})}
						</Stack>
					</Card>
				)}
				<MarketingPlatformBillingDetails accountId={accountId} />
				{account && (
					<Card title="Usage" sectioned>
						{!details && <SkeletonBodyText lines={3} />}
						{details && (
							<Stack spacing="none" vertical>
								<div>
									<strong>Online Store 2.0</strong>:{' '}
									{!themeSupport || isLoadingThemeSupport ? 'Loading...' : themeSupport.supportsBlocks ? 'Yes' : 'No'}
								</div>
								<div>
									<strong>
										Backfill status:{' '}
										{account.backfillComplete ? (
											<Badge tone="success" progress="complete">
												Completed
											</Badge>
										) : (
											<Badge tone="attention" progress={account.backfillPercentComplete === 0 ? 'incomplete' : 'partiallyComplete'}>
												Running <FormatPercent value={account.backfillPercentComplete} />
											</Badge>
										)}
									</strong>
								</div>
								<div>
									<strong>List cleaning</strong>: {account.agreedToCleanList ? 'Active' : 'Not active'} (Total bad addresses:{' '}
									<FormatNumber value={details.totalFailedValidations} />, Total bad subscribers:{' '}
									<FormatNumber value={details.totalSubscriberFailedValidations} />, Status:{' '}
									{account.listCleaningComplete ? 'Completed' : 'Not completed'})
								</div>
								<div>
									<strong>List cleaning level</strong>: {CLEAN_LEVELS[account.listCleaningLevel]}{' '}
									{isLeadOrAdmin(user) && (
										<Button onClick={onShowListCleaningLevelModal} variant="plain">
											Change
										</Button>
									)}
								</div>
								<div>
									<strong>Suppression groups</strong>:{' '}
									{account.suppressionTags.length > 0
										? account.suppressionTags
												.map(tag => `${tag} (${getNumber(allSuppressionTags.find(t => t.tag === tag)?.total ?? 0)} emails)`)
												.join(', ')
										: 'None'}{' '}
									{isLeadOrAdmin(user) && (
										<Button onClick={onShowSuppressionTagsModal} variant="plain">
											Change
										</Button>
									)}
								</div>
								<div>
									<strong>Total suppressed emails:</strong>{' '}
									<FormatNumber
										value={account.suppressionTags.reduce((acc, tag) => acc + (allSuppressionTags.find(t => t.tag === tag)?.total ?? 0), 0)}
									/>
								</div>
								<div>
									<strong>Total Customers</strong>:{' '}
									{customerSummary ? <FormatNumber value={customerSummary.totalCustomers} /> : 'Loading...'}
								</div>
								<div>
									<strong>Total Subscribers</strong>: <FormatNumber value={details.totalSubscribers} />
								</div>
								<div>
									<strong>Total Orders</strong>: {customerSummary ? <FormatNumber value={customerSummary.totalOrders} /> : 'Loading...'}
								</div>
								{isLeadOrAdmin(user) && (
									<Stack spacing="tight" wrap={false}>
										<strong>Total Revenue</strong>:
										{customerSummary ? (
											<ConversionFormatMoney amount={customerSummary.totalRevenue} code={account.currency} />
										) : (
											<p>Loading...</p>
										)}
									</Stack>
								)}
								<div>
									<strong>Conversions</strong>: <FormatNumber value={details.totalConversions} />
								</div>
								<Stack spacing="tight" wrap={false}>
									<strong>Conversions Revenue: </strong>
									<ConversionFormatMoney amount={details.totalConversionsRevenue} code={account.currency} />
								</Stack>
								<div>
									<strong>Total Products</strong>: {details.totalProducts < 0 ? 'Unknown' : <FormatNumber value={details.totalProducts} />}
								</div>
								<div>
									<strong>Total Newsletters Sent</strong>: <FormatNumber value={details.totalCampaignsSent} />
								</div>
							</Stack>
						)}
					</Card>
				)}
				<MarketingPlatformConnections accountId={accountId} />
				<Automations accountId={accountId} />
				<RecentNewsletters accountId={accountId} />
				<ScheduledNewsletters accountId={accountId} />
				<DraftNewsletters accountId={accountId} />

				<MarketingPlatformAccountAppPurchases appPurchases={appPurchases} isLoading={isAppPurchasesLoading} />
				<ManualJobs manualJobs={[manualJobs]} isLoading={isLoadingManualJobs} onRefetch={refetchManualJobs} accountId={accountId} />
				<JobsView onLoad={onLoadJobs} jobs={jobs} isLoading={isJobsLoading} />

				<Modal
					title="Set sending domain"
					primaryAction={{
						content: 'Set sending domain',
						onAction: performSetSendingDomain,
						loading: isSettingSendingDomain
					}}
					secondaryActions={[{ content: 'Cancel', onAction: () => setShowSendingDomainModal(false) }]}
					onClose={() => setShowSendingDomainModal(false)}
					open={showSendingDomainModal}>
					<Modal.Section>
						<Form onSubmit={performSetSendingDomain}>
							<FormLayout>
								<TextField label="Domain" autoComplete="off" value={sendingDomain} onChange={setSendingDomain} autoFocus />
							</FormLayout>
						</Form>
					</Modal.Section>
				</Modal>
				{account && (
					<Modal
						title="Confirm suspension"
						primaryAction={{
							content: account.suspended ? 'Unsuspend' : 'Suspend',
							onAction: () => setSuspended(!account.suspended),
							destructive: true,
							loading: isSettingSuspended
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmSuspendedModal(false) }]}
						onClose={() => setShowConfirmSuspendedModal(false)}
						open={showConfirmSuspendedModal}>
						<Modal.Section>
							{account.suspended
								? 'This will unsuspend the account. All paused campaigns will need to be rescheduled and all previously activated programs will need to be reactivated.'
								: 'This will suspend the account. All scheduled campaigns will be paused and all active programs will be deactivated.'}
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Confirm suspicious"
						primaryAction={{
							content: account.suspicious ? 'Unmark suspicious' : 'Mark suspicious',
							onAction: () => setSuspicious(!account.suspicious),
							destructive: true,
							loading: isSettingSuspicious
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmSuspiciousModal(false) }]}
						onClose={() => setShowConfirmSuspiciousModal(false)}
						open={showConfirmSuspiciousModal}>
						<Modal.Section>
							{account.suspicious
								? 'This will unmark the account as suspicious. All paused campaigns will need to be rescheduled and all previously activated programs will need to be reactivated.'
								: 'This will mark the account as suspicious. All attempts to schedule a campaigns or activate programs will cause the account to become susipicious.'}
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Confirm verify domain"
						primaryAction={{ content: 'Verify', onAction: verifyDomain, loading: isVerifyingDomain }}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmDomainVerifyModal(false) }]}
						onClose={() => setShowConfirmDomainVerifyModal(false)}
						open={showConfirmDomainVerifyModal}>
						<Modal.Section>
							<p>
								This will attempt to verify the domain with SparkPost. The domain must have the corresponding DNS records setup in order to
								verify correctly. If the domain is blocked in SparkPost, this action will not be able to verify the domain.
							</p>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Change compliance hold"
						primaryAction={{
							content: 'Change status',
							onAction: () => setComplianceHold(complianceHoldStatus),
							destructive: true,
							loading: isSettingComplianceHold
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmComplianceHoldModal(false) }]}
						onClose={() => setShowConfirmComplianceHoldModal(false)}
						open={showConfirmComplianceHoldModal}>
						<Modal.Section>
							<FormLayout>
								<p>
									Turning on a compliance hold will cause all scheduled newsletters to be paused and all active automations will be
									deactivated. These must be rescheduled after the compliance hold is lifted.
								</p>
								<ChoiceList
									title="Status"
									choices={[
										{ label: 'None', value: 'NONE' },
										{ label: 'Untrusted Content', value: 'UNTRUSTED_CONTENT' },
										{ label: 'Awaiting survey', value: 'AWAITING_SURVEY' },
										{ label: 'Pending review', value: 'PENDING_REVIEW' },
										{ label: 'Awaiting customer', value: 'AWAITING_CUSTOMER' },
										{ label: 'Subscriber import', value: 'SUBSCRIBER_IMPORT' },
										{ label: 'Unusual activity', value: 'UNUSUAL_ACTIVITY' }
									]}
									selected={[complianceHoldStatus]}
									onChange={selected => setComplianceHoldStatus(selected[0])}
								/>
							</FormLayout>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Confirm trusted"
						primaryAction={{
							content: account.trusted ? 'Untrust account' : 'Trust account',
							onAction: () => setTrusted(!account.trusted),
							destructive: account.trusted,
							loading: isSettingTrusted
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmTrustedModal(false) }]}
						onClose={() => setShowConfirmTrustedModal(false)}
						open={showConfirmTrustedModal}>
						<Modal.Section>
							{account.trusted
								? 'This will untrust the account. External links used in templates may cause this account to be put on a compliance hold.'
								: 'This will trust the account. All external links in templates will be allowed.'}
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Confirm trusted importer"
						primaryAction={{
							content: account.trustedImporter ? 'Untrust account imports' : 'Trust account imports',
							onAction: () => setTrustedImporter(!account.trustedImporter),
							destructive: account.trustedImporter,
							loading: isSettingTrustedImporter
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmTrustedImporterModal(false) }]}
						onClose={() => setShowConfirmTrustedImporterModal(false)}
						open={showConfirmTrustedImporterModal}>
						<Modal.Section>
							{account.trustedImporter
								? 'This will untrust imports for the account. Imports may result in compliance holds.'
								: 'This will trust imports for the account. The account will never be placed on compliance hold.'}
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Confirm sending override"
						primaryAction={{
							content: account.allowSendingOverride ? 'Disallow sending' : 'Allow sending',
							onAction: () => setAllowSendingOverride(!account.allowSendingOverride),
							destructive: true,
							loading: isSettingAllowSendingOverride
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmAllowSendingOverrideModal(false) }]}
						onClose={() => setShowConfirmAllowSendingOverrideModal(false)}
						open={showConfirmAllowSendingOverrideModal}>
						<Modal.Section>
							{!account.allowSendingOverride
								? 'This will enable sending for this account. All paused campaigns will need to be rescheduled and all previously activated programs will need to be reactivated.'
								: 'This will disable sending for the account. All scheduled campaigns will be paused and all active programs will be deactivated.'}
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Confirm allow sending while warming"
						primaryAction={{
							content: account.allowSendingWhileWarming ? 'Disallow sending' : 'Allow sending',
							onAction: () => setAllowSendingWhileWarming(!account.allowSendingWhileWarming),
							destructive: true,
							loading: isSettingAllowSendingWhileWarming
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmAllowSendingWhileWarmingModal(false) }]}
						onClose={() => setShowConfirmAllowSendingWhileWarmingModal(false)}
						open={showConfirmAllowSendingWhileWarmingModal}>
						<Modal.Section>
							{!account.allowSendingWhileWarming
								? 'This will enable sending during domain warming for this account.'
								: 'This will disable sending during warming for this account.'}
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Set IP pool"
						primaryAction={{
							content: 'Set',
							onAction: performSetIpPool,
							loading: isSettingIpPool,
							disabled: isIpPoolsLoading
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowSetIpPoolModal(false) }]}
						onClose={() => setShowSetIpPoolModal(false)}
						open={showSetIpPoolModal}>
						<Modal.Section>
							<Form onSubmit={performSetIpPool}>
								<FormLayout>
									<ChoiceList
										title="IP pool"
										choices={ipPoolsToShow}
										selected={[ipPool]}
										onChange={selected => setIpPool(selected[0])}
										disabled={isIpPoolsLoading}
									/>
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Set screen record"
						primaryAction={{
							content: 'Set',
							onAction: performSetScreenRecord,
							loading: isSettingScreenRecord
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowScreenRecordModal(false) }]}
						onClose={() => setShowScreenRecordModal(false)}
						open={showScreenRecordModal}>
						<Modal.Section>
							<Form onSubmit={performSetScreenRecord}>
								<FormLayout>
									<ChoiceList
										title="Screen record"
										choices={[
											{ label: 'Active', value: true },
											{ label: 'Inactive', value: false }
										]}
										selected={[screenRecord]}
										onChange={selected => setScreenRecord(selected[0])}
										disabled={isSettingScreenRecord}
									/>
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Set unsubscribe text"
						primaryAction={{
							content: 'Set',
							onAction: performSetUnsubscribeText,
							loading: isSettingUnsubscribeText
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowSetUnsubscribeModal(false) }]}
						onClose={() => setShowSetUnsubscribeModal(false)}
						open={showSetUnsubscribeModal}>
						<Modal.Section>
							<Form onSubmit={performSetUnsubscribeText}>
								<FormLayout>
									<LanguageSelector value={unsubscribeLanguage} onChange={onChangeUnsubscribeLanguage} options={getLanguageOptions()} />
									<TextField label="Unsubscribe text" autoComplete="off" value={unsubscribeText} onChange={setUnsubscribeText} />
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Set contact"
						primaryAction={{
							content: 'Set',
							onAction: performSetContact,
							loading: isSettingContact
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowSetContactModal(false) }]}
						onClose={() => setShowSetContactModal(false)}
						open={showSetContactModal}>
						<Modal.Section>
							<Form onSubmit={performSetContact}>
								<FormLayout>
									<TextField label="Name" autoComplete="off" value={contactName} onChange={setContactName} autoFocus />
									<TextField type="email" label="Email" autoComplete="off" value={contactEmail} onChange={setContactEmail} />
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Confirm warming reset"
						primaryAction={{
							content: 'Reset',
							onAction: resetWarming,
							destructive: true,
							loading: isResettingWarming
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowConfirmResetWarmingModal(false) }]}
						onClose={() => setShowConfirmResetWarmingModal(false)}
						open={showConfirmResetWarmingModal}>
						<Modal.Section>
							<p>This will reset the domain's warmed status.</p>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Export customers"
						primaryAction={{
							content: 'Export',
							onAction: performExportCustomers,
							loading: isExportingCustomers
						}}
						secondaryActions={[{ content: 'Cancel', onAction: onHideExportCustomersModal }]}
						onClose={onHideExportCustomersModal}
						open={showExportCustomersModal}>
						<Modal.Section>
							<Form onSubmit={performExportCustomers}>
								<FormLayout>
									<ChoiceList
										title="Export type"
										choices={EXPORT_TYPES}
										selected={[exportType]}
										onChange={selected => setExportType(selected[0])}
										disabled={isExportingCustomers}
										titleHidden
									/>
									{isSniffTestExport && (
										<Checkbox label="Include tags" checked={includeCustomerTagsForExport} onChange={setIncludeCustomerTagsForExport} />
									)}
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Set list cleaning level"
						primaryAction={{
							content: 'Set level',
							onAction: performSetListCleaningLevel,
							loading: isSettingListCleaningLevel
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowListCleaningLevelModal(false) }]}
						onClose={() => setShowListCleaningLevelModal(false)}
						open={showListCleaningLevelModal}>
						<Modal.Section>
							<Form onSubmit={performSetListCleaningLevel}>
								<FormLayout>
									<ChoiceList
										title="List cleaning level"
										choices={Object.keys(CLEAN_LEVELS).map(level => ({
											label: CLEAN_LEVELS[level],
											value: level,
											disabled: level === 'NONE' && !isLeadOrAdmin(user)
										}))}
										selected={[listCleaningLevel]}
										onChange={selections => setListCleaningLevel(selections[0])}
									/>
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Set suppression groups"
						primaryAction={{
							content: 'Set groups',
							onAction: performSetSuppressionGroups,
							loading: isSettingSuppressionTags
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowSuppressionTagsModal(false) }]}
						onClose={() => setShowSuppressionTagsModal(false)}
						open={showSuppressionTagsModal}>
						<Modal.Section>
							<Form onSubmit={performSetSuppressionGroups}>
								<FormLayout>
									<SuppressionTagsField accountId={account.id} suppressionTags={suppressionTags} onChange={setSuppressionTags} />
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Update trial"
						primaryAction={{
							content: isExtendTrialOption ? 'Extend' : 'Remove',
							onAction: onUpdateTrial,
							loading: isUpdatingTrial,
							disabled: isExtendTrialOption && !trialExtensionDate,
							destructive: !isExtendTrialOption
						}}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowUpdateTrialModal(false) }]}
						onClose={() => setShowUpdateTrialModal(false)}
						open={showUpdateTrialModal}>
						<Modal.Section>
							<Form onSubmit={onUpdateTrial}>
								<FormLayout>
									{getUpdateTrialBannerText().length > 0 && <Banner tone="critical">{getUpdateTrialBannerText()}</Banner>}
									<ChoiceList choices={getUpdateTrialOptions()} selected={updateTrialOption} onChange={setUpdateTrialOption} />
									{isExtendTrialOption && (
										<DateTimePicker
											dateLabel="End date"
											timeLabel="End time"
											timezone={account.timezone}
											disableDatesBefore={new Date()}
											onChange={date => setTrialExtensionDate(getUnixTime(date))}
											value={fromUnixTime(trialExtensionDate)}
										/>
									)}
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Set customer success note"
						primaryAction={{ content: 'Set note', onAction: onSetCsmNote, loading: isSettingCsmNote }}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowCsmNoteModal(false) }]}
						footer={
							account.csmNote ? (
								<Button onClick={onRemoveCsmNote} disabled={isSettingCsmNote} variant="primary" tone="critical">
									Remove note
								</Button>
							) : undefined
						}
						onClose={() => setShowCsmNoteModal(false)}
						open={showCsmNoteModal}>
						<Modal.Section>
							<Form>
								<FormLayout>
									<TextField id="title" label="Title" value={csmNoteFields.title} onChange={onSetCsmNoteField} autoFocus />
									<TextField id="note" label="Note" value={csmNoteFields.note} onChange={onSetCsmNoteField} multiline />
									<ChoiceList
										title="Status"
										choices={[
											{ label: 'Info', value: 'info' },
											{ label: 'Warning', value: 'warning' },
											{ label: 'Critical', value: 'critical' },
											{ label: 'Success', value: 'success' }
										]}
										selected={[csmNoteFields.status]}
										onChange={selections => onSetCsmNoteField(selections[0], 'status')}
									/>
									<Banner title={csmNoteFields.title || undefined} tone={csmNoteFields.status}>
										<p>{csmNoteFields.note}</p>
									</Banner>
									<Checkbox
										label="Dismissed"
										checked={Boolean(csmNoteFields.dismissedAt)}
										onChange={checked => onSetCsmNoteField(checked ? new Date() : null, 'dismissedAt')}
									/>
								</FormLayout>
							</Form>
						</Modal.Section>
					</Modal>
				)}
				{account && (
					<Modal
						title="Set Shopify notice"
						primaryAction={
							account.supportsResourceFeedback
								? {
										content: 'Set notice',
										onAction: onSetResourceFeedback,
										loading: isSettingResourceFeedback,
										disabled: !Boolean(resourceFeedbackMessage)
									}
								: undefined
						}
						secondaryActions={[{ content: 'Cancel', onAction: () => setShowResourceFeedbackModal(false) }]}
						onClose={() => setShowResourceFeedbackModal(false)}
						open={showResourceFeedbackModal}>
						<Modal.Section>
							{!account.supportsResourceFeedback && <p>This account hasn't approved the permissions to allow these messages yet.</p>}
							{account.supportsResourceFeedback && (
								<Form onSubmit={onSetResourceFeedback}>
									<FormLayout>
										<TextField
											id="message"
											label="Message"
											value={resourceFeedbackMessage}
											onChange={setResourceFeedbackMessage}
											helpText="Example: is awaiting your response. Please fill out the account survey."
											disabled={!account.supportsResourceFeedback}
											maxLength={100}
											showCharacterCount
											autoFocus
										/>
										<Banner tone="warning">
											<p>Seguno: Email Marketing {resourceFeedbackMessage}</p>
										</Banner>
									</FormLayout>
								</Form>
							)}
						</Modal.Section>
					</Modal>
				)}
				{account && <AppCreditModal account={account} app="SEGUNO" onClose={onCloseAppCreditModal} open={showAppCreditModal} />}
			</Layout>
		</AdminPage>
	);
}

function LinkedDnsDomain({ domain }) {
	return (
		<Link url={`https://viewdns.info/dnsrecord/?domain=${domain}`} target="_blank">
			{domain}
		</Link>
	);
}

const DkimContainer = styled.div`
	margin: 20px;
`;

const Dkim = styled.div`
	word-wrap: break-word;
	font-family: 'Courier New', Courier, monospace;
	font-size: 12px;
`;
