import { Stack, TextStyle } from '@segunosoftware/equinox';
import { Checkbox, Form, FormLayout, Loading, Modal, TextField, TopBar } from '@shopify/polaris';
import { AlertCircleIcon, CalendarTimeIcon, CashDollarIcon, DeleteIcon, ExitIcon, ForkliftIcon } from '@shopify/polaris-icons';
import { useIsFetching } from '@tanstack/react-query';
import md5 from 'md5';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAccessToken } from '../AccessTokenProvider';
import ToastProvider from '../ToastProvider';
import { useEraseCustomerData } from '../hooks/useEraseCustomerData';
import { useUser } from '../hooks/useUser';
import { isLeadOrAdmin, isSupport } from './AccountView';
import AppFrame from './AppFrame';
import Dashboard from './Dashboard';
import ShopView from './ShopView';
import BannerAccountView from './banner/BannerAccountView';
import BulkAccountView from './bulk/BulkAccountView';
import CanvaAccountView from './canva/CanvaAccountView';
import MailchimpConnectorAccountView from './mailchimpconnector/MailchimpConnectorAccountView';
import AllManualJobs from './marketingplatform/AllManualJobs';
import GlobalAppPurchases from './marketingplatform/GlobalAppPurchases';
import MarketingPlatformAccountView from './marketingplatform/MarketingPlatformAccountView';
import MarketingPlatformAnnouncements from './marketingplatform/MarketingPlatformAnnouncements';
import SendMonitoring from './marketingplatform/SendMonitoring';
import PopupAccountView from './popups/PopupAccountView';
import ReviewAccountView from './reviews/ReviewAccountView';

export default function Layout() {
	const isFetching = useIsFetching();
	const user = useUser();
	const { onLogout } = useAccessToken();
	const { eraseCustomerData, isCustomerDataErasing, isCustomerDataErasureSuccessful } = useEraseCustomerData();
	const [userMenuOpen, setUserMenuOpen] = useState(false);
	const [customerDataErasureEmail, setCustomerDataErasureEmail] = useState('');
	const [showCustomerDataErasureModal, setShowCustomerDataErasureModal] = useState(false);
	const [customerVerified, setCustomerVerified] = useState(false);
	const [customerErasureError, setCustomerErasureError] = useState(null);
	const [showEraseConfirmed, setShowEraseConfirmed] = useState(false);

	const isLoading = isFetching > 0;

	function onShowCustomerDataErasureModal() {
		setShowCustomerDataErasureModal(true);
		setCustomerVerified(false);
		setCustomerDataErasureEmail('');
		setCustomerErasureError(null);
	}

	function onToggleUserMenu() {
		setUserMenuOpen(userMenuOpen => !userMenuOpen);
	}

	function onEraseData() {
		if (customerDataErasureEmail.trim() === '') {
			setCustomerErasureError('An email must be provided.');
			return;
		}
		if (!customerVerified) {
			return;
		}
		setCustomerErasureError(null);
		eraseCustomerData(customerDataErasureEmail);
	}

	function onHideCustomerDataErasureModal() {
		setShowCustomerDataErasureModal(false);
	}

	useEffect(() => {
		if (isCustomerDataErasureSuccessful) {
			setShowEraseConfirmed(true);
			onHideCustomerDataErasureModal();
		}
	}, [isCustomerDataErasureSuccessful]);

	const actionItems = [
		{
			content: 'Purchases',
			icon: CashDollarIcon,
			url: '/app-purchases'
		},
		{
			content: 'Manual jobs',
			icon: ForkliftIcon,
			url: '/manual-jobs'
		},
		{
			content: 'Send monitoring',
			icon: CalendarTimeIcon,
			url: '/send-monitoring'
		}
	];
	if (user && isSupport(user)) {
		actionItems.unshift({
			content: 'Customer erasure',
			icon: DeleteIcon,
			disabled: !isSupport(user),
			onAction: onShowCustomerDataErasureModal
		});
	}
	if (user && isLeadOrAdmin(user)) {
		actionItems.unshift({
			content: 'Announcements',
			icon: AlertCircleIcon,
			url: '/marketing-platform-announcements'
		});
	}
	const userMenu = user && (
		<TopBar.UserMenu
			actions={[{ items: actionItems }, { items: [{ content: 'Log out', icon: ExitIcon, onAction: onLogout }] }]}
			name={`${user.firstName} ${user.lastName}`}
			detail={user.role.split('_').join(' ')}
			initials={`${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`}
			avatar={`https://gravatar.com/avatar/${md5(user.email.toLowerCase())}?d=404`}
			onToggle={onToggleUserMenu}
			open={userMenuOpen}
		/>
	);

	return (
		<AppFrame topBar={<TopBar userMenu={userMenu} />}>
			{isLoading && <Loading />}
			<ToastProvider>
				{user && (
					<Routes>
						<Route path="accounts" element={<ShopView />} />
						<Route path="banner-accounts/:accountId" element={<BannerAccountView />} />
						<Route path="bulk-accounts/:accountId" element={<BulkAccountView />} />
						<Route path="marketing-platform-accounts/:accountId" element={<MarketingPlatformAccountView />} />
						<Route path="popup-accounts/:accountId" element={<PopupAccountView />} />
						<Route path="review-accounts/:accountId" element={<ReviewAccountView />} />
						<Route path="canva-accounts/:accountId" element={<CanvaAccountView />} />
						<Route path="mailchimp-connector-accounts/:accountId" element={<MailchimpConnectorAccountView />} />
						<Route path="app-purchases" element={<GlobalAppPurchases />} />
						<Route path="marketing-platform-announcements" element={<MarketingPlatformAnnouncements />} />
						<Route path="send-monitoring" element={<SendMonitoring />} />
						<Route path="manual-jobs" element={<AllManualJobs />} />
						<Route path="" element={<Dashboard />} />
					</Routes>
				)}
			</ToastProvider>
			<Modal
				title="Erase customer data"
				primaryAction={{
					content: 'Erase data',
					onAction: onEraseData,
					destructive: true,
					disabled: customerDataErasureEmail.trim() === '' || !customerVerified,
					loading: isCustomerDataErasing
				}}
				secondaryActions={[{ content: 'Cancel', onAction: onHideCustomerDataErasureModal, disabled: isCustomerDataErasing }]}
				onClose={onHideCustomerDataErasureModal}
				open={showCustomerDataErasureModal}>
				<Modal.Section>
					<Stack vertical>
						<div>
							This will delete all customer data for this email address in <TextStyle variation="strong">all accounts</TextStyle>. This
							action cannot be undone. Please verify with the owner of the email that they want this to be done.
						</div>
						<div>
							After erasure is complete, a file will be downloaded to your computer with a list of domains their email was associated with.
							Please provide that list to the customer.
						</div>
						<Form onSubmit={onEraseData}>
							<FormLayout>
								<TextField
									type="email"
									label="Email address"
									autoComplete="off"
									value={customerDataErasureEmail}
									onChange={setCustomerDataErasureEmail}
									error={customerErasureError}
									autoFocus
								/>
								<Checkbox
									label="I have verified with the customer that they want their data erased"
									checked={customerVerified}
									onChange={setCustomerVerified}
								/>
							</FormLayout>
						</Form>
					</Stack>
				</Modal.Section>
			</Modal>
			<Modal
				title="Customer data erasure completed"
				primaryAction={{ content: 'Close', onAction: () => setShowEraseConfirmed(false) }}
				onClose={() => setShowEraseConfirmed(false)}
				open={showEraseConfirmed}>
				<Modal.Section>
					<p>
						The customer data has been successfully erased and a file has been downloaded to your computer containing the domains that the
						customer was associated with. Please contact the customer with that information to complete the process.
					</p>
				</Modal.Section>
			</Modal>
		</AppFrame>
	);
}
