import { Layout } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBulkAccount } from '../../hooks/bulk/useBulkAccount';
import { useUser } from '../../hooks/useUser';
import AccountView, { isLeadOrAdmin } from '../AccountView';
import AdminPage from '../AdminPage';
import AppCreditModal from '../AppCreditModal';
import JobsView from '../JobsView';
import BulkBillingAndUsageSummary from './BulkBillingAndUsageSummary';
import DiscountSets from './DiscountSets';

export default function BulkAccountView() {
	const { accountId } = useParams();
	const { account, jobs, onLoadJobs, isJobsLoading } = useBulkAccount(accountId);
	const user = useUser();

	const [showAppCreditModal, setShowAppCreditModal] = useState(false);
	const onCloseAppCreditModal = useCallback(() => setShowAppCreditModal(false), []);

	const billingActions = [];

	function addBillingOptions(hasPermissions, options) {
		if (hasPermissions) {
			billingActions.push(...options);
		}
	}

	if (account) {
		addBillingOptions(isLeadOrAdmin(user), [
			{
				content: 'Issue app credit',
				onAction: () => setShowAppCreditModal(true)
			}
		]);
	}

	return (
		<AdminPage
			title={account ? account.name : 'Bulk account'}
			backAction={{ content: 'Dashboard', url: '/' }}
			secondaryActions={billingActions}>
			<Layout sectioned>
				<AccountView account={account} app="BULK" />
				{account && <BulkBillingAndUsageSummary account={account} />}
				<DiscountSets accountId={Number(accountId)} />
				<JobsView jobs={jobs} onLoad={onLoadJobs} isLoading={isJobsLoading} />
			</Layout>
			{account && <AppCreditModal account={account} app="BULK" onClose={onCloseAppCreditModal} open={showAppCreditModal} />}
		</AdminPage>
	);
}
